import '@iconify/iconify';
import { MDCDrawer } from '@material/drawer';
import { MDCRipple } from '@material/ripple';
import { MDCTopAppBar } from '@material/top-app-bar';
import { makePopup } from '@typeform/embed';
import { v4 as uuidv4 } from 'uuid';

function main() {
  set_browser_patches();
  init_drawer();
  init_sec_feat_buttons();
  init_typeform();
}

function init_drawer() {
  const drawer_el = document.querySelector('#drawer');
  if (drawer_el != null) {
    const drawer = MDCDrawer.attachTo(drawer_el);
    drawer.initialize();
    const drawer_list = document.querySelector('.mdc-drawer .mdc-list');
    const main_content = document.querySelector('body > section:first-of-type');
    const handle_drawer_close = (ev: Event) => {
      drawer.open = false;
      main_content?.querySelector<HTMLButtonElement>('input, button')?.focus();
    };
    drawer_list?.addEventListener('click', handle_drawer_close);
    document.body.addEventListener('MDCDrawer:closed', handle_drawer_close);

    const top_app_bar_el = document.querySelector('.mdc-top-app-bar');
    if (top_app_bar_el != null) {
      const top_app_bar = new MDCTopAppBar(top_app_bar_el);
    }

    const drawer_button_el = document.querySelector('.nav-drawer-button');
    if (drawer_button_el != null) {
      const drawer_button = new MDCRipple(drawer_button_el);
      drawer_button.unbounded = true;
      drawer_button.listen('click', ev => {
        drawer.open = !drawer.open;
      });
    }
  }
}

function init_sec_feat_buttons() {
  const buttons = Array.from(
    document.querySelectorAll<HTMLInputElement>('.sec-feat-button'),
  );

  if (buttons.length > 0) {
    buttons[0].checked = true;

    for (const button of buttons) {
      document
        .querySelector(`[for=${button.id}]`)
        ?.addEventListener('mouseover', ev => {
          if ((ev.target as HTMLElement).localName === 'label') {
            (document.querySelector(
              `#${(ev.target as HTMLLabelElement).getAttribute('for')}`,
            ) as HTMLInputElement).click();
          }
        });

      button.addEventListener('change', ev => {
        const target = ev.target as HTMLInputElement;
        const target_form = target.closest('form');
        if (target_form != null) {
          target_form.dataset.secFeat = target.value;
        }
      });
    }
  }
}

function init_typeform() {
  const buy_buttons = Array.from(
    document.querySelectorAll<HTMLLinkElement>('.typeform-share.popup'),
  );

  for (const button of buy_buttons) {
    button.addEventListener('click', ev => {
      ev.preventDefault();

      const info_el: HTMLElement | null = (ev.target as HTMLElement).closest(
        '.item-data',
      );

      if (
        info_el == null ||
        info_el.dataset.safeName == null ||
        info_el.dataset.price == null ||
        info_el.dataset.originalPrice == null ||
        info_el.dataset.discountPercent == null ||
        info_el.dataset.form == null
      ) {
        console.error('Unknown item', ev.target);
        throw 'Unknown item';
      }

      const safe_name = info_el.dataset.safeName;
      const price = Number.parseFloat(info_el.dataset.price);
      const original_price = Number.parseFloat(info_el.dataset.originalPrice);
      const discount_percent = Number.parseFloat(
        info_el.dataset.discountPercent,
      );
      const form = info_el.dataset.form;

      send_analytics_event('purchase form', 'preparing', safe_name, 10);
      // productSKU, productName, productCategory, price, quantity
      _paq.push(['addEcommerceItem', safe_name, 'Destiny 2', price, 1]);

      const typeform_btn: HTMLElement | null = info_el.querySelector(
        '.typeform-share',
      );

      makePopup(form, {
        open: 'load',
        mode: (typeform_btn?.dataset.mode as any) || 'drawer_right',
        hideHeaders: typeform_btn?.dataset.hideHeaders === 'true',
        hideFooter: typeform_btn?.dataset.hideFooter === 'true',
        onReady: () => {
          send_analytics_event('purchase form', 'ready', safe_name, 1);
        },
        onClose: () => {
          send_analytics_event('purchase form', 'closed', safe_name);
          // productSKU
          _paq.push(['removeEcommerceItem', safe_name]);
        },
        onSubmit: () => {
          send_analytics_event('purchase form', 'submitted', safe_name, price);

          // orderId, revenue, subTotal, tax, shipping, discount
          _paq.push([
            'trackEcommerceOrder',
            uuidv4(),
            price,
            price,
            0,
            0,
            discount_percent > 0 ? original_price * discount_percent : false,
          ]);
        },
      });
    });
  }
}

function set_browser_patches() {
  const is_safari =
    navigator.vendor &&
    navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') == -1 &&
    navigator.userAgent.indexOf('FxiOS') == -1;

  if (is_safari) {
    document.body.classList.add('safari');
  }
}

function send_analytics_event(
  event: string,
  action: string,
  label: string,
  value?: number,
) {
  try {
    _paq.push(['trackEvent', event, action, label, value]);

    ga('send', 'event', {
      eventCategory: event,
      eventAction: action,
      eventLabel: label,
      eventValue: value,
    });
  } catch (err) {
    console.error(err);
  }
}

if (
  document.readyState === 'complete' ||
  document.readyState === 'interactive'
) {
  main();
} else {
  document.addEventListener('DOMContentLoaded', main);
}
